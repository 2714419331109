$(".servicesGrid__api__card__copy").click(function(){
  var token = $(".value-token").text();

  var $temp = $("<input>");
  $("body").append($temp);
  $temp.val(token).select();
  document.execCommand("copy");
  $temp.remove();

  $(".servicesGrid__api__card__copy").addClass('servicesGrid__api__card__copy--copied');
  setTimeout(function() {
    $(".servicesGrid__api__card__copy").removeClass('servicesGrid__api__card__copy--copied');
  }, 500);
});
