// Roda funÃ§Ã£o de load
esconderErro();

// Fecha a box do erro
function esconderErro() {
  setTimeout(function() {
    $(".alertas").toggleClass("closed");
  }, 10000);
}

// FunÃ§Ã£o para fechar o bloco no clique
$("span.fechar-alerta").click(function() {
  $(".alertas").toggleClass("clickclose");
});
