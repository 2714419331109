// Mascara de CPF ou CNPJ
var options = {
  onKeyPress: function(cpf, ev, el, op) {
    var masks = ["000.000.000-000", "00.000.000/0000-00"];
    $(".doc").mask(cpf.length > 14 ? masks[1] : masks[0], op);
  }
};

// Mascara de documentos CPF ou CNPJ
$(".doc").length > 11
  ? $(".doc").mask("00.000.000/0000-00", options)
  : $(".doc").mask("000.000.000-00#", options);

// Mascara de CPF
$(".cpf").mask("000.000.000-00");
$(".doc-1").mask("000.000.000-00");

// Mascara de CNPJ
$(".cnpj").mask("00.000.000/0000-00");
$(".doc-2").mask("00.000.000/0000-00");

// Mascara de telefone
$(".telefone").mask("(00) 0000-0000");

// Mascara de celular
$(".celular").mask("(00) 00000-0000");

// Mascara de celular
$(".cep").mask("00000-000");

// Celular ou telefone
$(".celcontato").mask("(00) 0000-00000");

//Money
$('.money').mask('#.##0,00', {reverse: true});
